.announcement-list {
    text-align: center;
  }
  
  .announcement-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .announcement-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    transition: transform 0.3s ease;
  }
  
  .announcement-card:hover {
    transform: translateY(-5px);
  }
  
  .announcement-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .announcement-info {
    padding: 15px;
  }
  
  .announcement-info h3 {
    margin: 10px 0;
  }
  
  .announcement-actions {
    display: flex;
    justify-content: space-around;
  }
  
  .edit-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .admin-btn {
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  