.table-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.members-list {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f4f4f9; /* Arka planı tabloyla aynı genişlikte olacak şekilde hizaladık */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Taşma durumunda yatay kaydırma çubuğu ekler */
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 2.2rem;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
}

thead th {
  background-color: #007bff;
  color: white;
  padding: 12px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
}

tbody td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #e1e5ee;
}

tbody tr:hover {
  background-color: #e6f7ff;
}

button {
  background-color: #dc3545;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead {
    display: none;
  }

  tr {
    margin-bottom: 15px;
  }

  td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 50%;
    text-align: left;
    font-weight: bold;
  }
}
