/* contact-page.css - Masaüstü korunarak mobil uyumlu hale getirildi */

/* Genel sayfa düzeni */
.contact-page {
    max-width: 1200px;
    margin: 150px auto;
    padding: 60px;
    background: linear-gradient(135deg, #f1f4f9, #c2d2e9);
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
}

/* Başlık */
.contact-page h1 {
    font-size: 3.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
    animation: fadeInDown 1s ease-out;
}

/* Form düzeni */
.contact-form {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
}

.form-group {
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group.full-width {
    width: 100%;
}

label {
    font-size: 1.4rem;
    font-weight: 700;
    color: #444;
    margin-bottom: 10px;
    text-align: left;
    transition: color 0.3s ease;
}

/* Placeholder mesajları */
input::placeholder,
textarea::placeholder {
    color: #888;
    font-style: italic;
    opacity: 1;
    transition: opacity 0.2s ease;
}

input:not(:focus)::placeholder,
textarea:not(:focus)::placeholder {
    opacity: 1;
}

input:focus::placeholder,
textarea:focus::placeholder {
    opacity: 0;
}

/* Input ve textarea stili */
input,
textarea {
    padding: 18px;
    font-size: 1.2rem;
    border: 2px solid #ccd6dd;
    border-radius: 12px;
    background-color: #fff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 12px rgba(0, 123, 255, 0.2);
}

textarea {
    height: 180px;
    resize: vertical;
}

/* Gönder butonu */
.submit-btn {
    background-color: #64976f;
    color: #fff;
    padding: 18px 50px;
    font-size: 1.4rem;
    font-weight: 700;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px;
}

.submit-btn:hover {
    background-color: #218838;
    transform: translateY(-4px);
}

/* Mesaj sonrası */
.response-message {
    color: green;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
    font-size: 1.4rem;
}

/* Animasyonlu gölgeler */
.contact-page::before,
.contact-page::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: rgba(0, 123, 255, 0.15);
    animation: floatAnimation 6s infinite ease-in-out;
}

.contact-page::before {
    width: 300px;
    height: 300px;
    top: -100px;
    right: -100px;
}

.contact-page::after {
    width: 150px;
    height: 150px;
    bottom: -50px;
    left: -50px;
}

/* Mobil uyumlu medya sorguları */
@media (max-width: 768px) {
    .contact-page {
        padding: 30px;
        margin: 100px 20px;
    }

    .form-group {
        width: 100%;
    }

    .contact-page h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }

    .submit-btn {
        width: 100%;
        padding: 15px;
    }
}

/* Animasyonlar */
@keyframes floatAnimation {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(30px, -30px);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}