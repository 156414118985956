/* footer.css - Masaüstü korunarak mobil uyumlu footer yeniden yazıldı */

footer {
  background: linear-gradient(90deg, rgba(40, 40, 40, 0.9), rgba(60, 60, 60, 0.8));
  padding: 50px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-section,
.footer-center,
.footer-section-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section ul,
.footer-center ul,
.footer-section-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li a,
.footer-section-right ul li a {
  color: #bbbbbb;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section ul li a:hover,
.footer-section-right ul li a:hover {
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 25px;
  padding-top: 2%;
}

.social-icons a {
  color: #e0e0e0;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ffffff;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  color: #cccccc;
  font-size: 0.9rem;
  margin-top: 20px;
}

/* Mobil uyumlu medya sorguları */
@media screen and (max-width: 768px) {
  footer {
    flex-direction: column;
    text-align: center;
    padding: 20px 10px;
  }

  .footer-section,
  .footer-center,
  .footer-section-right {
    margin-bottom: 20px;
  }

  .social-icons {
    gap: 15px;
  }

  .footer-bottom {
    font-size: 0.8rem;
  }
}