/* Genel stil tanımları */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a1a;
  color: white;
}

.App {
  text-align: center;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* Yatay kaydırmayı gizler ve sağdaki boşlukları engeller */
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}