.form-group {
    position: relative;
    margin-bottom: 20px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background-color: #f9f9f9;
    padding: 0 5px;
    color: #aaa;
    font-size: 1rem;
    transition: 0.3s ease all;
    pointer-events: none;
  }
  
  .form-group input:focus + label,
  .form-group textarea:focus + label,
  .form-group input:not(:placeholder-shown) + label,
  .form-group textarea:not(:placeholder-shown) + label {
    top: -10px;
    left: 5px;
    font-size: 0.75rem;
    color: #333;
    background-color: #f9f9f9;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  