:root {
  --primary: #4361ee;
  --primary-dark: #3f37c9;
  --error: #f72585;
  --text-dark: #343a40;
  --bg-light: #f8f9fa;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 20px;
}

.login-form {
  background: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  backdrop-filter: blur(10px);
  transform: translateY(0);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-form:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(67, 97, 238, 0.15);
}

.login-form h2 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--text-dark);
  font-size: 28px;
  font-weight: 600;
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-group {
  margin-bottom: 25px;
  position: relative;
}

.input-group input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.input-group input:focus {
  border-color: var(--primary);
  outline: none;
  box-shadow: 0 0 0 4px rgba(67, 97, 238, 0.1);
}

.login-form button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.login-form button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: 0.5s;
}

.login-form button:hover::before {
  left: 100%;
}

.login-form button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.3);
}

.error-message {
  color: var(--error);
  font-size: 14px;
  margin-top: 10px;
  padding: 10px;
  background: rgba(247, 37, 133, 0.1);
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Tasarım */
@media (max-width: 480px) {
  .login-form {
    padding: 30px 20px;
  }

  .login-form h2 {
    font-size: 24px;
  }

  .input-group input {
    padding: 12px;
    font-size: 14px;
  }

  .login-form button {
    padding: 12px;
    font-size: 14px;
  }
}

/* Tablet ve Küçük Ekranlar */
@media (max-width: 768px) {
  .login-container {
    padding: 15px;
  }
}