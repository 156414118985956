:root {
    --primary: #4361ee;
    --primary-light: #4895ef;
    --primary-dark: #3f37c9;
    --secondary: #4cc9f0;
    --success: #4CAF50;
    --warning: #ff9800;
    --danger: #f72585;
    --light: #f8f9fa;
    --dark: #343a40;
    --gradient-start: #4361ee;
    --gradient-end: #4895ef;
    --panel-bg: #ffffff;
    --border-radius: 8px;
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.certificate-editor {
    display: flex;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    padding: 20px;
    gap: 20px;
    background: #f8f9fa;
}

.editor-panel {
    flex: 0 0 350px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: var(--border-radius);
    padding: 20px;
    box-shadow: var(--shadow);
    overflow-y: auto;
    max-height: calc(100vh - 100px);
}

.panel-section {
    margin-bottom: 24px;
    padding: 16px;
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    border: 1px solid rgba(67, 97, 238, 0.1);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.panel-section h3 {
    margin: 0 0 16px 0;
    color: var(--dark);
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
}

.upload-section {
    background: #fff;
    border: 2px dashed var(--primary-light);
    border-radius: var(--border-radius);
    padding: 20px;
    text-align: center;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.upload-section input[type="file"] {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    cursor: pointer;
    font-size: 0.9rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.upload-section:hover {
    border-color: var(--primary);
    background: #f8f9fa;
}

.control-group {
    margin-bottom: 16px;
}

.control-group label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    color: var(--dark);
    font-weight: 500;
}

.value-badge {
    background: linear-gradient(to right, var(--primary), var(--primary-light));
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
}

.slider-with-input {
    display: flex;
    gap: 10px;
    align-items: center;
}

.slider-with-input input[type="range"] {
    flex: 1;
    height: 6px;
    background: linear-gradient(to right, var(--primary-light) 0%, var(--primary-light) 50%, #e9ecef 50%, #e9ecef 100%);
    border-radius: 3px;
    appearance: none;
}

.slider-with-input input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: var(--primary);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
}

.slider-with-input input[type="range"]::-webkit-slider-thumb:hover {
    transform: scale(1.2);
}

.slider-with-input input[type="number"] {
    width: 70px;
    padding: 4px 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    text-align: center;
}

.color-picker {
    width: 100%;
    height: 40px;
    padding: 4px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.font-selector {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.font-search {
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    margin-bottom: 8px;
}

select {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    background: white;
    cursor: pointer;
}

.preview-name-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    margin-bottom: 16px;
    font-size: 1rem;
}

.names-list textarea {
    width: 100%;
    min-height: 150px;
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    resize: vertical;
    font-family: inherit;
}

.save-btn,
.load-position-btn {
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: none;
    border-radius: var(--border-radius);
    background: var(--primary);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.save-btn:hover,
.load-position-btn:hover {
    background: var(--primary-dark);
    transform: translateY(-1px);
}

.load-position-btn {
    background: var(--secondary);
    flex-direction: column;
}

.load-position-btn small {
    font-size: 0.8rem;
    opacity: 0.8;
}

.generate-btn {
    width: 100%;
    padding: 16px;
    border: none;
    border-radius: var(--border-radius);
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.generate-btn:hover:not(:disabled) {
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary) 100%);
    box-shadow: 0 4px 8px rgba(67, 97, 238, 0.3);
}

.generate-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.progress-bar {
    margin-top: 16px;
    height: 6px;
    background: #e9ecef;
    border-radius: 3px;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background: linear-gradient(90deg,
            var(--primary) 0%,
            var(--secondary) 50%,
            var(--primary) 100%);
    background-size: 200% auto;
    animation: progress 2s linear infinite;
}

.preview-panel {
    flex: 1;
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    border-radius: var(--border-radius);
    padding: 20px;
    box-shadow: var(--shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.canvas-container {
    position: relative;
    transform-origin: center;
    transition: transform 0.3s ease;
    will-change: transform;
    transform: translateZ(0);
}

.certificate-preview {
    max-width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

.zoom-controls {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.95);
    padding: 8px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 8px;
    align-items: center;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(67, 97, 238, 0.1);
}

.zoom-btn {
    padding: 8px;
    border: none;
    border-radius: 4px;
    background: var(--primary);
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoom-btn:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.zoom-level {
    font-size: 0.9rem;
    color: var(--dark);
    font-weight: 500;
    min-width: 50px;
    text-align: center;
}

/* Tooltip Styles */
[data-tooltip] {
    position: relative;
}

[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 0.8rem;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000;
}

/* Responsive Tasarım */
@media (max-width: 1024px) {
    .certificate-editor {
        flex-direction: column;
    }

    .editor-panel {
        flex: none;
        width: 100%;
        max-height: none;
    }

    .preview-panel {
        height: 500px;
    }
}

@media (max-width: 768px) {
    .certificate-editor {
        padding: 10px;
    }

    .panel-section {
        padding: 12px;
    }

    .preview-panel {
        height: 400px;
    }
}

/* Animasyon İyileştirmeleri */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.panel-section {
    animation: fadeIn 0.3s ease-out;
}

/* Hover Efektleri */
.control-group label:hover .value-badge {
    transform: scale(1.05);
    transition: transform 0.2s ease;
}

/* Mobil Optimizasyonları */
@media (max-width: 1024px) {
    .certificate-editor {
        gap: 15px;
        padding: 15px;
    }

    .editor-panel {
        max-height: none;
    }
}

@media (max-width: 768px) {
    .panel-section {
        margin-bottom: 15px;
    }

    .slider-with-input {
        flex-direction: column;
        gap: 5px;
    }

    .slider-with-input input[type="number"] {
        width: 100%;
    }
}

/* Yükleme Animasyonu */
@keyframes progress {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.progress-bar-fill {
    background: linear-gradient(90deg,
            var(--primary) 0%,
            var(--secondary) 50%,
            var(--primary) 100%);
    background-size: 200% auto;
    animation: progress 2s linear infinite;
}

/* Gölge ve Derinlik Efektleri */
.save-btn,
.load-position-btn,
.generate-btn {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.save-btn:hover,
.load-position-btn:hover,
.generate-btn:hover:not(:disabled) {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Input Geliştirmeleri */
input[type="text"],
input[type="number"],
select,
textarea {
    transition: all 0.3s ease;
    border: 1px solid rgba(67, 97, 238, 0.2);
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.1);
    outline: none;
}

.format-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
}

.format-btn {
    flex: 1;
    padding: 12px;
    border: 2px solid var(--primary-light);
    border-radius: var(--border-radius);
    background: white;
    color: var(--primary);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
}

.format-btn:hover {
    background: var(--primary-light);
    color: white;
    transform: translateY(-2px);
}

.format-btn.active {
    background: var(--primary);
    color: white;
    border-color: var(--primary);
}

.format-btn span {
    font-size: 1.2em;
}

/* Mobil için format butonları */
@media (max-width: 768px) {
    .format-controls {
        flex-direction: column;
    }

    .format-btn {
        width: 100%;
    }
}

.password-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa;
    padding: 20px;
}

.login-form {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-form h2 {
    text-align: center;
    margin-bottom: 30px;
    color: var(--dark);
}

.login-form .input-group {
    margin-bottom: 20px;
}

.login-form input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.login-form input:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.1);
}

.login-form button {
    width: 100%;
    padding: 12px;
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-form button:hover {
    background: var(--primary-dark);
    transform: translateY(-1px);
}

.error-message {
    color: var(--danger);
    font-size: 14px;
    margin-top: 8px;
    padding: 8px;
    background: rgba(247, 37, 133, 0.1);
    border-radius: 4px;
    text-align: center;
}