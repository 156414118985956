/* acm-page.css - Masaüstü korunarak mobil uyumlu hale getirildi */
.acm-page {
  background-color: #000428;
  /* Gece mavisi arka plan rengi */
  background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3) 1.5px, transparent 1.5px),
    radial-gradient(circle at 70% 30%, rgba(255, 255, 255, 0.3) 1.8px, transparent 1.8px),
    radial-gradient(circle at 30% 70%, rgba(255, 255, 255, 0.3) 2.5px, transparent 2.5px);
  background-size: 150px 150px, 250px 250px, 350px 350px;
  background-attachment: fixed;
  animation: starAnimation 30s linear infinite;
  padding: 50px;
  position: relative;
}

/* Yıldızların animasyonu */
@keyframes starAnimation {
  0% {
    background-position: 0 0, 0 0, 0 0;
  }

  100% {
    background-position: 1500px 1500px, 1200px 1200px, 1000px 1000px;
  }
}

.section-title {
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 80px;
  position: relative;
  z-index: 1;
  display: inline-block;
  font-family: cursive;
}

.section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #fff;
  max-width: 800px;
  margin: 0 auto 60px auto;
  text-align: center;
  font-style: italic;
}

.glow-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  animation: pulse 6s ease-in-out infinite;
  opacity: 0.8;
  z-index: 0;
}

.glow-circle:nth-child(1) {
  top: 20%;
  left: 5%;
  width: 400px;
  height: 400px;
}

.glow-circle:nth-child(2) {
  bottom: 10%;
  right: 5%;
  width: 350px;
  height: 350px;
  animation-delay: 1.5s;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.acm-logo {
  display: block;
  margin: 100px auto 20px auto;
  width: 200px;
  height: auto;
  margin-bottom: 55px;
}

.divider {
  width: 1000px;
  height: 3px;
  background: linear-gradient(to right, #5a1f3a, #198ba1);
  margin: 0 auto;
  margin-bottom: 40px;
}

.acm-subtitle {
  font-size: 7rem;
  font-weight: bold;
  margin: 140px auto 20px auto;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 2px;
  font-family: cursive;
}

/* Mobil uyumlu medya sorguları */
@media screen and (max-width: 768px) {
  .section-title {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .section p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 40px;
  }

  .acm-logo {
    width: 150px;
    margin: 50px auto 20px auto;
  }

  .divider {
    width: 90%;
    height: 2px;
    margin-bottom: 20px;
  }

  .acm-subtitle {
    font-size: 3rem;
    margin: 80px auto 20px auto;
  }

  .glow-circle {
    width: 150px;
    height: 150px;
  }

  .glow-circle:nth-child(1) {
    top: 10%;
    left: 10%;
    width: 200px;
    height: 200px;
  }

  .glow-circle:nth-child(2) {
    bottom: 5%;
    right: 10%;
    width: 180px;
    height: 180px;
  }
}