/* Genel stil */
.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #121212;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .not-found h1 {
    font-size: 8rem;
    color: #bdc7ce;
    margin: 0;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 99, 71, 0.8);
    animation: bounce 1s infinite;
  }
  
  .not-found h2 {
    font-size: 2.5rem;
    margin-top: 10px;
    color: #fff;
  }
  
  .not-found p {
    margin: 20px 0;
    margin-bottom: 40px;
    font-size: 1.2rem;
    color: #ddd;
  }
  
  .not-found-link {
    text-decoration: none;
    padding: 10px 25px;
    background-color: #287c9d;
    color: #fff;
    border-radius: 50px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #0056b3;
    color: #fff;
    transform: translateY(-5px);
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Mobil cihazlar için */
  @media (max-width: 768px) {
    .not-found h1 {
      font-size: 5rem;
    }
  
    .not-found h2 {
      font-size: 1.8rem;
    }
  }
  