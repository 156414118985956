.duyurular-page {
  padding: 50px;
  background-color: #bcc943;
  min-height: 100vh;
}

.duyurular-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 50px;
  margin-top: 40px;
  color: #ffffff;
  padding-top: 30px;
  font-family: cursive;
}

.loading-message,
.error-message,
.no-announcements {
  text-align: center;
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.loading-message {
  color: #2980b9;
}

.error-message {
  color: #e74c3c;
  border: 2px solid #e74c3c;
}

.no-announcements {
  color: #7f8c8d;
}

.duyurular-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
}

.duyuru-card {
  background-color: rgba(245, 244, 249, 0.656);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  transition: transform 0.3s ease;
}

.duyuru-card:hover {
  transform: translateY(-5px);
}

.duyuru-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.duyuru-info {
  padding: 20px;
}

.duyuru-info h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #2e4053;
}

.duyuru-info p {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

button:hover {
  background-color: #0056b3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  position: relative;
}

.modal-content img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.modal-content h2 {
  margin-top: 20px;
  color: rgb(0, 0, 0);
}

.modal-content p {
  margin-top: 10px;
  color: #fff;
  background-color: #007bff;
  padding: 10px;
  border-radius: 5px;
}

.close {
  position: absolute;
  top: -5px;
  right: 1px;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
}

@media (max-width: 768px) {
  .duyurular-page {
    padding: 30px;
  }

  .duyurular-title {
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .duyurular-container {
    gap: 15px;
    padding: 10px;
  }

  .duyuru-card {
    width: 100%;
  }

  .modal-content {
    margin: 20px;
    max-height: 90vh;
    overflow-y: auto;
  }
}