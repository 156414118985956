/* Genel admin panel arka planı */
.admin-panel {
  background-color: #16dd73;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  margin: 150px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Başlık düzeni */
.admin-panel h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
}

/* Tablo düzeni */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #007bff;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

td {
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
}

tr:nth-child(even) {
  background-color: #f1f1f1;
}

/* Tablo satırı üzerine geldiğinde */
tr:hover {
  background-color: #eaf5ff;
  cursor: pointer;
}

/* Sil butonu düzeni */
button {
  background-color: #dc3545;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #c82333;
}

/* Geri Dön butonu */
.back-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
}

.back-btn:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

/* Mesajlar tablosu düzenlemesi */
.table-container {
  margin-top: 20px;
  overflow-x: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobil uyum için */
@media (max-width: 768px) {

  th,
  td {
    font-size: 0.9rem;
    padding: 10px;
  }

  .admin-panel {
    padding: 30px;
    max-width: 95%;
  }

  .back-btn {
    width: 100%;
  }
}