/* signup.css - Mobil uyumlu hale getirildi */

.signup-page {
  padding: 50px;
  max-width: 900px;
  margin: 120px auto;
  /* Formu biraz aşağı çekmek için margin ekledim */
  background-color: #f0f8ff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.signup-page h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
}

.signup-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* İki sütunlu yapı */
  grid-gap: 20px;
  /* Boşluklar */
  margin-top: 20px;
  /* Üst boşluk ekleyerek formun başlıkla uyumlu olmasını sağladım */
}

.form-group {
  position: relative;
  width: 100%;
  /* Inputların tamamını kaplaması için %100 genişlik verdik */
}

.form-group input,
.form-group textarea {
  width: 100%;
  /* Inputlar tam genişlikte mobil uyumlu */
  padding: 15px;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  background-color: #eef7ff;
}

.form-group label {
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 1rem;
  color: #666;
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-group input:focus+label,
.form-group input:not(:placeholder-shown)+label,
.form-group textarea:focus+label,
.form-group textarea:not(:placeholder-shown)+label {
  top: -12px;
  left: 12px;
  font-size: 0.85rem;
  color: #007bff;
  background-color: #f0f8ff;
  padding: 0 5px;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-btn {
  grid-column: span 2;
  /* Butonun iki sütunu da kaplamasını sağladım */
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.success-message {
  grid-column: span 2;
  margin-top: 20px;
  text-align: center;
  color: #28a745;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .signup-page {
    padding: 20px;
    margin: 60px auto;
  }

  .signup-page h1 {
    font-size: 2rem;
    margin-top: 25px;
  }

  .signup-form {
    grid-template-columns: 1fr;
    /* Tek sütunlu yapı mobilde */
    grid-gap: 15px;
  }

  .submit-btn {
    grid-column: span 1;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    /* Tam genişlik inputlar */
  }
}