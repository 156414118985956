/* hero.css - Masaüstü korunarak mobil uyumlu hale getirildi */
.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../styles/agri-dagi.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  margin-top: 100px;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  filter: blur(2px);
}

.hero h1 {
  position: relative;
  z-index: 1;
  font-size: 4.5rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  animation: fadeIn 2s ease-in-out;
}

.neon-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  animation: float 6s ease-in-out infinite;
  opacity: 0;
  animation: fadeInCircle 4s ease-in-out 1.3s forwards;
  z-index: 0;
}

.neon-circle:nth-child(2) {
  width: 350px;
  height: 350px;
  top: -50px;
  left: -100px;
  /* Baloncuğu sola çekiyoruz */
}

.neon-circle:nth-child(3) {
  width: 400px;
  height: 400px;
  top: 150px;
  right: -150px;
  /* Baloncuğu sağa çekiyoruz */
  animation-delay: 1s;
}

/* Mobil uyumlu medya sorguları */
@media screen and (max-width: 768px) {
  .hero {
    height: 70vh;
    /* Mobilde yüksekliği azaltıyoruz */
    margin-top: 50px;
  }

  .hero h1 {
    font-size: 2.5rem;
    /* Mobilde yazı boyutunu küçültüyoruz */
    letter-spacing: 1px;
  }

  .neon-circle {
    width: 150px;
    height: 150px;
  }

  .neon-circle:nth-child(2) {
    top: 10px;
    left: 10px;
  }

  .neon-circle:nth-child(3) {
    top: auto;
    bottom: 10px;
    right: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInCircle {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}